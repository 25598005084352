
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";


img.avatar {
	border-radius: 50%;
}

.navbar-header .navbar-brand {
	border-right-width: 0px;
}